import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
import Furigana from '@components/furigana';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <style>{`
  /* some kinda jank sticky headers*/
  h3 {
    top: 0;
    position: sticky;
    background-color: white;
    padding: .05em;
    border-bottom: 1px solid gray;
  }
`}</style>
    <h3 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h3>
    <div className="table-of-contents">
      <ul>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "#types-of-verbs"
            }}>{`Types of verbs`}</a></p>
          <ul parentName="li">
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#%E3%82%8B-verbs-ichidan"
                }}>{`~る verbs (ichidan)`}</a></p>
            </li>
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#%E3%81%86-verbs-godan"
                }}>{`~う verbs (godan)`}</a></p>
            </li>
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#irregular-verbs"
                }}>{`Irregular verbs`}</a></p>
              <ul parentName="li">
                <li parentName="ul"><a parentName="li" {...{
                    "href": "#transitive-vs-intransitive-verbs"
                  }}>{`Transitive vs Intransitive verbs`}</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "#conjugations"
            }}>{`Conjugations`}</a></p>
          <ul parentName="li">
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#tldr"
                }}>{`TL;DR`}</a></p>
            </li>
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#%E3%80%9C%E3%81%BE%E3%81%99-polite"
                }}>{`〜ます (polite)`}</a></p>
            </li>
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#negative"
                }}>{`negative`}</a></p>
            </li>
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#past-tense"
                }}>{`past tense`}</a></p>
            </li>
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#%E3%80%9C%E3%81%9F%E3%81%84"
                }}>{`〜たい`}</a></p>
            </li>
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#%E3%81%A6-form"
                }}>{`て-form`}</a></p>
              <ul parentName="li">
                <li parentName="ul"><a parentName="li" {...{
                    "href": "#%E3%80%9C%E3%81%A6%E3%81%84%E3%82%8B"
                  }}>{`〜ている`}</a></li>
              </ul>
            </li>
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#%E3%80%9C%E3%81%AA%E3%81%95%E3%81%84"
                }}>{`〜なさい`}</a></p>
            </li>
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#conditional-%E3%81%B0-form-%EF%BC%88%E3%80%9C%E3%82%8C%E3%81%B0%EF%BC%89"
                }}>{`conditional "ば-form" （〜れば）`}</a></p>
            </li>
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#potential-%E3%81%88-form"
                }}>{`potential "え-form"`}</a></p>
            </li>
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#passive-%EF%BC%88%E3%80%9C%E3%82%89%E3%82%8C%E3%82%8B%EF%BC%89"
                }}>{`passive （〜られる）`}</a></p>
            </li>
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#causative-%EF%BC%88%E3%80%9C%E3%81%95%E3%81%9B%E3%82%8B%EF%BC%89"
                }}>{`causative （〜させる）`}</a></p>
            </li>
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#causative-passive-%EF%BC%88%E3%80%9C%E3%81%95%E3%81%9B%E3%82%89%E3%82%8C%E3%82%8B%EF%BC%89"
                }}>{`causative-passive （〜させられる）`}</a></p>
            </li>
            <li parentName="ul">
              <p parentName="li"><a parentName="p" {...{
                  "href": "#volitional"
                }}>{`volitional`}</a></p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "types-of-verbs"
    }}>{`Types of verbs`}</h2>
    <h3 {...{
      "id": "る-verbs-ichidan"
    }}>{`~る verbs (ichidan)`}</h3>
    <p>{`Always end with る (although not all verbs ending 「る」 are 〜る verbs)
Conjugations usually drop the 「る」`}</p>
    <h3 {...{
      "id": "う-verbs-godan"
    }}>{`~う verbs (godan)`}</h3>
    <p>{`End in some form of 「う」 sound (「く」, 「む」, `}<em parentName="p">{`even`}</em>{` 「る」)
Can conjugate differently depending on final mora (example: te-form, かく -> かいて, かう -> かって, よむ -> よんで)`}</p>
    <h3 {...{
      "id": "irregular-verbs"
    }}>{`Irregular verbs`}</h3>
    <p>{`する (to do) and `}<Furigana reading={'くる'} mdxType="Furigana">{`来る`}</Furigana>{` (to come), their behaviour seems somewhat predictable`}</p>
    <h4 {...{
      "id": "transitive-vs-intransitive-verbs"
    }}>{`Transitive vs Intransitive verbs`}</h4>
    <p>{`Transitive verbs are actions being performed on an object ＜ｎ＞を＜ｖ＞ます`}</p>
    <p>{`Intransitive verbs are performed by a subject ＜ｎ＞が＜ｖ＞ます`}</p>
    <p>{`おゆを I boil the water (transitive)
おゆが The water boils (intrasitive)`}</p>
    <h2 {...{
      "id": "conjugations"
    }}>{`Conjugations`}</h2>
    <h3 {...{
      "id": "tldr"
    }}>{`TL;DR`}</h3>
    <p>{`Ru verbs drop the ru, u verbs swap the u sound like so:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`〜う becomes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`masu`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜います`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`negative`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜あない`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tai`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜いたい`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`potential`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜える`}</td>
        </tr>
      </tbody>
    </table>
    <p><a parentName="p" {...{
        "href": "#%E3%81%A6-form"
      }}>{`て-form is it's own beast though`}</a></p>
    <h3 {...{
      "id": "〜ます-polite"
    }}>{`〜ます (polite)`}</h3>
    <p>{`〜る verbs drop the 「る」
For 〜う verbs, the 「う」sound becomes an「い」sound (eg, かう -> かいます)`}</p>
    <ul>
      <li parentName="ul">{`Negative: 〜ません`}</li>
      <li parentName="ul">{`Past tense: 〜ました`}</li>
      <li parentName="ul">{`Negative past tense: 〜ませんでした`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`plain`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`たべる`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`よむ`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`mora`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`masu-ending`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜います`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`かう -> かいます (to buy)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜く`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜きます`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`かく -> かきます (to write/draw)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ります`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`とる -> とります (to take)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜む`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜みます`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`のむ -> のみます (to drink)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぬ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜にます`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`しぬ -> しにます (to die)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜つ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ちます`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`もつ -> もちます (to hold)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぶ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜びます`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`あそぶ -> あそびます (to play)`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "negative"
    }}>{`negative`}</h3>
    <p>{`For plain form negatives`}</p>
    <p>{`〜る verbs drop the 「る」
For 〜う verbs, the 「う」sound becomes an「あ」sound (eg, かく -> かかない), with the exception of 「う」 becoming 「わ」(かう -> かわない)`}</p>
    <p>{`〜ない`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`plain`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`たべる`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`よむ`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`mora`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`nai-ending`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜わない`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`かう -> かわない (to buy)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜く`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜きます`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`かく -> かかない (to write/draw)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ります`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`とる -> とらない (to take)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜む`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜みます`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`のむ -> のまない (to drink)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぬ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜にます`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`しぬ -> しなない (to die)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜つ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ちます`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`もつ -> もたない (to hold)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぶ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜びます`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`あそぶ -> あそばない (to play)`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`ある is a special case, and just becomes ない`}</p>
    <ul>
      <li parentName="ul">{`Past tense: 〜なかった`}</li>
    </ul>
    <h3 {...{
      "id": "past-tense"
    }}>{`past tense`}</h3>
    <p>{`〜た`}</p>
    <h3 {...{
      "id": "〜たい"
    }}>{`〜たい`}</h3>
    <p>{`To want to, い-adjective`}</p>
    <h3 {...{
      "id": "て-form"
    }}>{`て-form`}</h3>
    <p>{`て-form is used for commands (often with ください, `}<Furigana reading={'み'} mdxType="Furigana">見</Furigana>てください). It is also used when chaining verbs _<Furigana reading={'もってきて'} mdxType="Furigana">{`持って来て`}</Furigana><em parentName="p">{` (to bring, _lit: to have and come`}</em>{`)`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`mora`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`te-ending`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜む`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜んで`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`よむ -> よんで`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "〜ている"
    }}>{`〜ている`}</h4>
    <p>{`Used to represent a verb's continued action.`}</p>
    <p>{`For `}<em parentName="p">{`transitive verbs`}</em>{`, this is an on-going action`}</p>
    <p>{`For `}<em parentName="p">{`intransitive verbs`}</em>{`, this is an on-going `}<em parentName="p">{`state`}</em>{` of the verb`}</p>
    <h3 {...{
      "id": "〜なさい"
    }}>{`〜なさい`}</h3>
    <p>{`Similar to 〜て, but more firm, talking down to someone of a lesser status (or in exam papers)`}</p>
    <p>{`Uses a ます-form verb stem`}</p>
    <p>{`「〜う」-> 「〜いなさい」
「〜る」-> 「〜なさい」`}</p>
    <h3 {...{
      "id": "conditional-ば-form-（〜れば）"
    }}>{`conditional "ば-form" （〜れば）`}</h3>
    <p>{`ば-form is a conditional form, usually the result is positive, used like `}<em parentName="p">{`食べれば、...`}</em>{` (`}<em parentName="p">{`If I ate it then,...`}</em>{`, similar to 〜なら)`}</p>
    <p>{`〜なければいけません is a form form meaning `}<em parentName="p">{`I must do `}{`[verb]`}</em>{` (casually shortened to 〜なきゃ)`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`mora`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ba-ending`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜えば`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`よむ -> よめば`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜れば`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`たべる -> たべれば`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`すれば`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`くれば`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ない`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`なければ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ない -> なければ`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ない`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜なければ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`こない -> こなければ`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`mora`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`conditional`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜えば`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`かう -> かえば (to buy)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜く`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜けば`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`かく -> かけば (to write/draw)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜れば`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`とる -> とれば (to take)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜む`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜めば`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`のむ -> のめば (to drink)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぬ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ねば`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`しぬ -> しねば (to die)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜つ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜てば`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`もつ -> もてば (to hold)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぶ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜べば`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`あそぶ -> あそべば (to play)`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The negative form (〜ない -> 〜なければ), is commonly used as a set phrase with 「いけません」, to say one `}<em parentName="p">{`must`}</em>{` do something, like 「宿題をしなければいけません」(or I must do my homework)`}</p>
    <h3 {...{
      "id": "potential-え-form"
    }}>{`potential "え-form"`}</h3>
    <p>{`〜る verbs drop the 「る」and get 〜られる
For 〜う verbs, the 「う」sound becomes an「え」sound (eg, かう -> かえます)`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`plain`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`たべる`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`よむ`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`mora`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`potential`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜える`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`かう -> かえる (to buy)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜く`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ける`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`かく -> かける (to write/draw)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜れる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`とる -> とれる (to take)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜む`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜める`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`のむ -> のめる (to drink)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぬ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ねる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`しぬ -> しねる (to die)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜つ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜てる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`もつ -> もてる (to hold)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぶ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜べる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`あそぶ -> あそべる (to play)`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "passive-（〜られる）"
    }}>{`passive （〜られる）`}</h3>
    <p>{`Passive forms are used when an action is being done to the subject. Used like `}{`[target]`}{`は`}{`[perpatrator]`}{`に`}{`[action]`}{`.`}</p>
    <p>{`The rule is similar to potential form,
〜る verbs drop the 「る」and get 〜られる
For 〜う verbs, the 「う」sound becomes an「あ」sound then also gets 〜れる (eg, かう -> かわれる)
(Similar to negatives, 「う」becomes「わ」)`}</p>
    <p>{`ぼくはみんなにわらわれる - everyone laughed a me`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`plain`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`vol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`たべる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`たべられる`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`よむ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`よまれる`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`される`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`こられる`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Passive ru verbs share the same rule as potential ru verbs (although it's practically the same as u passive verbs too!)`}</p>
    <h3 {...{
      "id": "causative-（〜させる）"
    }}>{`causative （〜させる）`}</h3>
    <p>{`Causitive forms are used when allowed or made to do something. Used like `}{`[making]`}{`は`}{`[made to]`}{`に`}{`[action]`}{`.`}</p>
    <p>{`〜る verbs drop the 「る」and get 〜させる
For 〜う verbs, the 「う」sound becomes an「あ」sound then also gets 〜せる (eg, かう -> かわせる)
(Similar to negatives, 「う」becomes「わ」)`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`plain`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`causative`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`たべる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`たべさせる`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`よむ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`よませる`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`させる`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`こさせる`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`mora`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`causative`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜わせる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`かう -> かわせる (to buy)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜く`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜かせる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`かく -> かかせる (to write/draw)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜らせる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`とる -> とらせる (to take)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜む`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ませる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`のむ -> のませる (to drink)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぬ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜なせる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`しぬ -> しなせる (to die)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜つ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜たせる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`もつ -> もたせる (to hold)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぶ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ばせる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`あそぶ -> あそばせる (to play)`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "causative-passive-（〜させられる）"
    }}>{`causative-passive （〜させられる）`}</h3>
    <p>{`Causitive-passive forms are used when made to do something (usually against their will). The passive version of a causitive clause. Used like `}{`[made to]`}{`は`}{`[making]`}{`に`}{`[action]`}{` (the opposite of causitive, similar to passive).`}</p>
    <p>{`〜る verbs drop the 「る」and get 〜させられる
For 〜う verbs, the 「う」sound becomes an「あ」sound then also gets 〜される (eg, かう -> かわされる)
`}<strong parentName="p">{`〜す is the exception, they instead get the る ending 〜させられる`}</strong>{`
(Similar to negatives, 「う」becomes「わ」)`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`plain`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`pot`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`たべる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`たべさせられる`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`よむ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`よまされる`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜す＊`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`はなす`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`はなさせられる`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`させられる`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`こさせられる`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`mora`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`causative`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜わされる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`かう -> かわされる (to buy)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜く`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜かされる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`かく -> かかされる (to write/draw)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜らされる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`とる -> とらされる (to take)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜む`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜まされる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`のむ -> のまされる (to drink)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぬ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜なされる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`しぬ -> しなされる (to die)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜つ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜たされる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`もつ -> もたされる (to hold)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぶ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ばされる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`あそぶ -> あそばされる (to play)`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "volitional"
    }}>{`volitional`}</h3>
    <p>{`For plain form volitional`}</p>
    <p>{`〜る verbs drop the 「る」
For 〜う verbs, the 「う」sound becomes an「お」sound and gains an「う」to become 「〜おう」 (eg, かう -> かおう),`}</p>
    <p>{`〜よう`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`plain`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`vol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`たべる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`たべよう`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`よむ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`よもう`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`する`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`しよう`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`くる`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`こよう`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`mora`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`volitional`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜う`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜おう`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`かう -> かおう (to buy)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜く`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜こう`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`かく -> かこう (to write/draw)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜る`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ろう`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`とる -> とろう (to take)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜む`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜もう`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`のむ -> のもう (to drink)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぬ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜のう`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`しぬ -> しのう (to die)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜つ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜とう`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`もつ -> もとう (to hold)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぶ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`〜ぼう`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`あそぶ -> あそぼう (to play)`}</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`masu volitional: 〜ましょう`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      